import React, { useState, useEffect, useContext } from "react";
import { graphql, Link } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";
import { withPreview } from "gatsby-source-prismic";
import Helmet from "react-helmet";
import styled from "styled-components";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { FurnitureFilters } from "../components/navigation/furniture-filters";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { Spacer } from "../components/utils/spacer";

// Effects
import { Fade } from "react-awesome-reveal";

// Context
import { FooterColor } from "../components/context/footer-color";

// Utils
import { DetermineFooterColor } from "../components/utils/determine-footer-color";

const Page = styled.div`
	margin: 150px 0 0 0;
`;

const FurnitureContainer = styled.div`
	width: 100%;

	& .collection-container {
		margin: 0 0 100px 0;
	}

	& .collection-details {
		margin: 0 0 60px 0;

		& .title {
			margin: 0 0 10px 0;
		}
	}

	@media (max-width: 768px) {
		& .hidden {
			display: none;
		}
	}
`;

const Product = styled.div`
	opacity: ${(props) => props.opacity};
	transition: 150ms opacity ease;

	& p {
		margin: 10px 0 40px 0;
		opacity: ${(props) => props.titleOpacity};
	}

	&:hover {
		opacity: 0.25;
		transition: 150ms opacity ease;
	}
`;

const Furniture = ({ data }) => {
	const [activeFilter, setActiveFilter] = useState("All");

	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		const calcFooterColor = DetermineFooterColor(
			data.prismicFurniture.data.body[
				data.prismicFurniture.data.body.length - 1
			].slice_type
		);

		setFooterColor(calcFooterColor);
	}, []);

	if (data.prismicFurniture !== null) {
		const content = data.prismicFurniture.data.body.map((content, index) => {
			const products = content.items.map((item, index) => (
				<Col
					col={6}
					md={4}
					xl={3}
					key={`single_related_product_${item.product.document.id}_${index}`}
					className={
						item.product.document.data.linked_product.product_type ===
							activeFilter || activeFilter === "All"
							? `visible`
							: `hidden`
					}
				>
					<Link
						to={`/products/${item.product.document.data.linked_product.handle}`}
					>
						<Fade cascade triggerOnce>
							<Product
								className="product"
								opacity={
									item.product.document.data.linked_product.product_type ===
										activeFilter || activeFilter === "All"
										? 1
										: 0.2
								}
								titleOpacity={
									item.product.document.data.linked_product.product_type ===
										activeFilter || activeFilter === "All"
										? 1
										: 0
								}
							>
								<AspectRatioImageContainer image={null} padding={75}>
									{item.product.document.data.thumbnail.fluid !== null && (
										<img
											srcSet={
												item.product.document.data.thumbnail.fluid.srcSetWebp
											}
											src={item.product.document.data.thumbnail.fluid.srcWebp}
											alt={item.product.document.data.thumbnail.alt}
											loading={`lazy`}
										/>
									)}
								</AspectRatioImageContainer>
								<p>{item.product.document.data.linked_product.title}</p>
							</Product>
						</Fade>
					</Link>
				</Col>
			));

			const collectionDetails = content.primary.linked_collection.document.data.body
				.filter((content) => content.slice_type === "introduction")
				.map((content, index) => (
					<div key={`single_collection_details_row_${index}`}>
						<div
							className="title"
							dangerouslySetInnerHTML={{
								__html: content.primary.collection_title.html,
							}}
						/>
					</div>
				));

			return (
				<Col col={12} key={`single_collection_row_${index}`}>
					<div className="collection-container">
						<Row>
							<Col col={12}>
								<Row>
									<Col col={12} md={5}>
										<div className="collection-details">
											{collectionDetails}
											<Link to={content.primary.linked_collection.document.url}>
												View Collection
											</Link>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>{products}</Row>
					</div>
				</Col>
			);
		});

		return (
			<>
				<Helmet
					title={`Furniture | Christian Watson`}
					meta={[
						{
							name: "og:title",
							content: `Furniture | Christian Watson`,
						},
					]}
				/>

				<Page>
					<FurnitureContainer>
						<FlexContainer
							desktopPadding={`0 60px 0 135px`}
							tabletPadding={`0 35px 0 135px`}
							mobilePadding={`0 15px`}
							cv={false}
						>
							<Row>
								<FurnitureFilters
									activeFilter={activeFilter}
									setActiveFilter={setActiveFilter}
								/>
								{content}
							</Row>
						</FlexContainer>
					</FurnitureContainer>
				</Page>
				<Spacer />
			</>
		);
	} else {
		return null;
	}
};

export const query = graphql`
	{
		prismicFurniture {
			uid
			data {
				title {
					html
				}
				body {
					... on PrismicFurnitureBodyCollection {
						id
						slice_type
						primary {
							linked_collection {
								id
								document {
									... on PrismicCollection {
										id
										url
										data {
											body {
												... on PrismicCollectionBodyIntroduction {
													id
													slice_type
													primary {
														collection_title {
															html
														}
														collection_text {
															text
														}
													}
												}
											}
										}
									}
								}
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
											thumbnail {
												alt
												fluid {
													srcWebp
													srcSetWebp
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default withPreview(Furniture);
