import React, { useEffect, useContext } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

// Context
import { MenuColorConsumer } from "../components/context/menu-color";
import { UpdateMenuColor } from "../components/utils/update-menu-color";
import { FooterColor } from "../components/context/footer-color";

// Components
import { DetermineModuleToDisplay } from "../components/utils/determine-module-to-display";
import { ContentContainer } from "../components/containers/container";
import { Spacer } from "../components/utils/spacer";

// Utils
import { DetermineFooterColor } from "../components/utils/determine-footer-color";

const Page = styled.div`
	@media (max-width: 768px) {
		padding: 60px 0 0 0;
	}
`;

const LimitedEditionCollectionContent = ({ data, setMenuColor }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		const calcFooterColor = DetermineFooterColor(
			data.prismicLimitedEditionCollection.data.body[
				data.prismicLimitedEditionCollection.data.body.length - 1
			].slice_type
		);

		setFooterColor(calcFooterColor);
	}, []);

	const content = data.prismicLimitedEditionCollection.data.body.map(
		(content, outerIndex) => {
			if (outerIndex === 0) {
				return (
					<DetermineModuleToDisplay
						content={content}
						pageType={`collection`}
						key={`collection_module_wrapper_${outerIndex}_${content.id}`}
					/>
				);
			} else {
				return (
					<ContentContainer
						key={`collection_module_wrapper_${outerIndex}_${content.id}`}
					>
						<DetermineModuleToDisplay
							content={content}
							pageType={`collection`}
							contentIndex={outerIndex}
						/>
					</ContentContainer>
				);
			}
		}
	);

	return (
		<>
			<Helmet
				title={`${data.prismicLimitedEditionCollection.data.title.text} | Christian Watson`}
				meta={[
					{
						name: "og:title",
						content: `${data.prismicLimitedEditionCollection.data.title.text} | Christian Watson`,
					},
				]}
			/>

			<MenuColorConsumer>
				{({ setMenuColor }) => (
					<UpdateMenuColor
						updatedMenuColor={`transparent`}
						setMenuColor={setMenuColor}
					/>
				)}
			</MenuColorConsumer>

			{content}

			<Spacer />
		</>
	);
};

const LimitedEditionCollection = ({ data }) => (
	<MenuColorConsumer>
		{({ setMenuColor }) => (
			<Page>
				<LimitedEditionCollectionContent
					data={data}
					setMenuColor={setMenuColor}
				/>
			</Page>
		)}
	</MenuColorConsumer>
);

export default withPreview(LimitedEditionCollection);

export const query = graphql`
	query LimitedEditionCollection($uid: String!) {
		prismicLimitedEditionCollection(uid: { eq: $uid }) {
			prismicId
			data {
				title {
					text
				}
				body {
					... on PrismicLimitedEditionCollectionBodyImage {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_position
							image_size
							link {
								url
								type
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyVideo {
						id
						slice_type
						primary {
							video {
								width
								height
								html
								embed_url
							}
							link {
								url
								type
							}
							video_position
							video_size
						}
					}
					... on PrismicLimitedEditionCollectionBodyImageBanner {
						id
						slice_type
						primary {
							image {
								fluid(
									imgixParams: { ar: "16:3", fit: "clamp", crop: "entropy" }
								) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							link {
								url
								type
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyVideoBanner {
						id
						slice_type
						primary {
							link {
								url
								type
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyText {
						id
						slice_type
						primary {
							text {
								html
							}
							text_columns
							text_position
						}
					}
					... on PrismicLimitedEditionCollectionBodyCollections {
						id
						slice_type
						items {
							collection {
								document {
									... on PrismicCollection {
										id
										url
										prismicId
										data {
											thumbnail {
												fluid {
													srcSetWebp
													srcWebp
													aspectRatio
												}
												alt
											}
											title {
												text
											}
										}
									}
								}
							}
						}
						primary {
							collections_title {
								html
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyMailchimp {
						id
						slice_type
						primary {
							mailchimp_form {
								document {
									... on PrismicMailchimp {
										id
										prismicId
										data {
											call_to_action_text
											privacy_policy {
												html
											}
											text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}

							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyLeIntroduction {
						id
						slice_type
						primary {
							collection_title {
								html
							}
							collection_text {
								html
							}
							collection_image {
								alt
								dimensions {
									height
									width
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyProducts {
						id
						slice_type
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
											thumbnail {
												alt
												fluid {
													srcWebp
													srcSetWebp
												}
											}
										}
									}
								}
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}
							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyEnquiryForm {
						id
						slice_type
						primary {
							enquiry_form {
								document {
									... on PrismicEnquiryForm {
										id
										data {
											form_title {
												html
											}
											form_text {
												html
											}
											form_message_placeholder
											form_disclaimer_text {
												html
											}
											dropdown_options {
												dropdown_option
												linked_email_address
											}
											additional_form_fields {
												additional_form_field
											}
											enable_file_upload
											file_upload_text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodyImageWithText {
						id
						slice_type
						primary {
							image_with_text {
								html
							}
							image_with_text_image {
								dimensions {
									width
									height
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							image_with_text_title {
								html
							}
							text_position
							text_vertical_position
						}
					}
					... on PrismicLimitedEditionCollectionBodyFaq {
						id
						slice_type
						primary {
							faq_section_title {
								html
							}
						}
						items {
							faq_title {
								text
							}
							faq_text {
								html
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodySlideshow {
						id
						slice_type
						primary {
							slideshow_title {
								html
							}
						}
						items {
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							text {
								html
							}
						}
					}
					... on PrismicLimitedEditionCollectionBodySlideshowWithText {
						id
						slice_type
						primary {
							slideshow_with_text_title {
								html
							}
							slideshow_with_text {
								html
							}
						}
						items {
							image_title {
								html
								text
							}
							image_text {
								html
								text
							}
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
						}
					}
				}
			}
		}
	}
`;
