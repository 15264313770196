import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { withUnpublishedPreview } from "gatsby-source-prismic";

// Styles
import { secondaryDark, tertiary } from "../components/utils/colors";

// Context
import { FooterColor } from "../components/context/footer-color";

// Pages
import Furniture from "../pages/furniture";

// Templates
import Article from "../templates/article";
import Collection from "../templates/collection";
import LimitedEditionCollection from "../templates/limited-edition-collection";
import Page from "../templates/page";
import PolicyPage from "../templates/policy-page";
import Product from "../templates/product";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	margin: 65px 0 0 0;
`;

const TextContainer = styled.div`
	max-width: 680px;
	width: 100%;

	margin: 0 auto;
	padding: 0 20px;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& a {
		&:hover {
			color: ${secondaryDark};
		}
	}
`;

const PageNotFound = () => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	return (
		<>
			<Helmet title={`Page Not Found | Christian Watson`} />
			<Container>
				<TextContainer>404</TextContainer>
			</Container>
		</>
	);
};

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(PageNotFound, {
	templateMap: {
		article: Article,
		collection: Collection,
		furniture: Furniture,
		limited_edition_collection: LimitedEditionCollection,
		page: Page,
		policy_page: PolicyPage,
		product: Product,
	},
});
