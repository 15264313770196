import React, { useState, useEffect, useContext } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

// Components
import { DetermineModuleToDisplay } from "../components/utils/determine-module-to-display";
import { ContentContainer } from "../components/containers/container";
import { CollectionNavigation } from "../components/navigation/collection-navigation";
import { Spacer } from "../components/utils/spacer";

// Context
import { FooterColor } from "../components/context/footer-color";

// Utils
import { DetermineFooterColor } from "../components/utils/determine-footer-color";

const Page = styled.div`
	@media (max-width: 768px) {
		padding: 60px 0 0 0;
	}
`;

const CollectionContent = ({
	data,
	location,
	activeFilter,
	setActiveFilter,
}) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		const calcFooterColor = DetermineFooterColor(
			data.prismicCollection.data.body[
				data.prismicCollection.data.body.length - 1
			].slice_type
		);

		setFooterColor(calcFooterColor);
	}, []);

	const content = data.prismicCollection.data.body.map(
		(content, outerIndex) => {
			let previousContentSliceType =
				outerIndex !== 0
					? data.prismicCollection.data.body[outerIndex - 1].slice_type
					: false;

			return (
				<ContentContainer
					key={`collection_module_wrapper_${outerIndex}_${content.id}`}
					id={content.slice_type === "products" ? `collection-products` : null}
					marginOverride={
						(content.slice_type === "enquiry_form" ||
							content.slice_type === "mailchimp") &&
						(previousContentSliceType === "video_-_banner" ||
							previousContentSliceType === "image_-_banner" ||
							previousContentSliceType === "related_products")
					}
				>
					<DetermineModuleToDisplay
						content={content}
						contentIndex={outerIndex}
						pageType={`collection`}
						activeFilter={activeFilter}
						setActiveFilter={setActiveFilter}
					/>

					{outerIndex === data.prismicCollection.data.body.length - 1 &&
						content.slice_type !== "enquiry_form" &&
						content.slice_type !== "mailchimp" &&
						content.slice_type !== "video_-_banner" &&
						content.slice_type !== "image_-_banner" &&
						content.slice_type !== "related_products" && <Spacer />}
				</ContentContainer>
			);
		}
	);

	useEffect(() => {
		if (typeof window !== undefined && location !== undefined) {
			if (
				location.state !== null &&
				location.state !== undefined &&
				location.state.activeFilter !== undefined
			) {
				var productsInCollection = document.getElementById(
					"collection-products"
				);

				if (productsInCollection !== null) {
					productsInCollection.scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "start",
					});
				}
			}
		}
	}, [content]);

	return (
		<>
			<Helmet
				title={`${data.prismicCollection.data.seo_title}`}
				meta={[
					{
						name: "og:title",
						content: `${data.prismicCollection.data.seo_title}`,
					},
					{
						name: "description",
						content: `${data.prismicCollection.data.seo_description}`,
					},
					{
						name: "og:description",
						content: `${data.prismicCollection.data.seo_description}`,
					},
					{
						name: "twitter:title",
						content: `${data.prismicCollection.data.seo_title}`,
					},
					{
						name: "twitter:description",
						content: `${data.prismicCollection.data.seo_description}`,
					},
				]}
			/>

			<CollectionNavigation />

			{content}
		</>
	);
};

const Collection = ({ data, location }) => {
	const [activeFilter, setActiveFilter] = useState("All");

	useEffect(() => {
		if (typeof window !== undefined && location !== undefined) {
			if (
				location.state !== null &&
				location.state !== undefined &&
				location.state.activeFilter !== undefined
			) {
				setActiveFilter(location.state.activeFilter);
			}
		}
	}, [location]);

	return (
		<Page>
			<CollectionContent
				data={data}
				activeFilter={activeFilter}
				setActiveFilter={setActiveFilter}
				location={location}
			/>
		</Page>
	);
};

export default withPreview(Collection);

export const query = graphql`
	query Collection($uid: String!) {
		prismicCollection(uid: { eq: $uid }) {
			prismicId
			data {
				title {
					text
				}
				seo_title
				seo_description
				body {
					... on PrismicCollectionBodyImage {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_position
							image_size
							link {
								url
								type
							}
						}
					}
					... on PrismicCollectionBodyVideo {
						id
						slice_type
						primary {
							video {
								width
								height
								html
								embed_url
							}
							link {
								url
								type
							}
							video_position
							video_size
						}
					}
					... on PrismicCollectionBodyImageBanner {
						id
						slice_type
						primary {
							image {
								fluid(
									imgixParams: { ar: "16:3", fit: "clamp", crop: "entropy" }
								) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							link {
								url
								type
							}
						}
					}
					... on PrismicCollectionBodyVideoBanner {
						id
						slice_type
						primary {
							link {
								url
								type
							}
							video {
								width
								height
								html
								embed_url
								title
							}
						}
					}
					... on PrismicCollectionBodyText {
						id
						slice_type
						primary {
							text {
								html
							}
							text_columns
							text_position
						}
					}
					... on PrismicCollectionBodyCollections {
						id
						slice_type
						items {
							collection {
								document {
									... on PrismicCollection {
										id
										url
										prismicId
										data {
											thumbnail {
												fluid {
													srcSetWebp
													srcWebp
													aspectRatio
												}
												alt
											}
											title {
												text
											}
										}
									}
								}
							}
						}
						primary {
							collections_title {
								html
							}
						}
					}
					... on PrismicCollectionBodyMailchimp {
						id
						slice_type
						primary {
							mailchimp_form {
								document {
									... on PrismicMailchimp {
										id
										prismicId
										data {
											call_to_action_text
											privacy_policy {
												html
											}
											text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicCollectionBodyIntroduction {
						id
						slice_type
						primary {
							collection_title {
								html
							}
							collection_text {
								html
							}
							collection_image {
								alt
								dimensions {
									height
									width
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
							}
						}
					}
					... on PrismicCollectionBodyProducts {
						id
						slice_type
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
											thumbnail {
												alt
												fluid {
													srcWebp
													srcSetWebp
												}
											}
										}
									}
								}
							}
						}
					}
					... on PrismicCollectionBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}

							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicCollectionBodyEnquiryForm {
						id
						slice_type
						primary {
							enquiry_form {
								document {
									... on PrismicEnquiryForm {
										id
										data {
											form_title {
												html
											}
											form_text {
												html
											}
											form_message_placeholder
											form_disclaimer_text {
												html
											}
											dropdown_options {
												dropdown_option
												linked_email_address
											}
											additional_form_fields {
												additional_form_field
											}
											enable_file_upload
											file_upload_text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicCollectionBodyImageWithText {
						id
						slice_type
						primary {
							image_with_text {
								html
							}
							image_with_text_image {
								dimensions {
									width
									height
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							image_with_text_title {
								html
							}
							text_position
							text_vertical_position
						}
					}
					... on PrismicCollectionBodyFaq {
						id
						slice_type
						primary {
							faq_section_title {
								html
							}
						}
						items {
							faq_title {
								text
							}
							faq_text {
								html
							}
						}
					}
					... on PrismicCollectionBodySlideshow {
						id
						slice_type
						primary {
							slideshow_title {
								html
							}
						}
						items {
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							text {
								html
							}
						}
					}
					... on PrismicCollectionBodySlideshowWithText {
						id
						slice_type
						primary {
							slideshow_with_text_title {
								html
							}
							slideshow_with_text {
								html
							}
						}
						items {
							image_title {
								html
								text
							}
							image_text {
								html
								text
							}
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
						}
					}
				}
			}
		}
	}
`;
