import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Styles
import { secondaryLight } from "../utils/colors";

const NavContainer = styled.div`
	position: fixed;

	top: 145px;
	left: 0;

	width: 135px;

	z-index: 1;

	& ol {
		margin: 0;
		padding: 0 15px;

		& li {
			& a {
				transition: 150ms color ease;

				&:hover {
					color: ${secondaryLight};
				}
			}

			& a.current {
				color: ${secondaryLight};
				&:hover {
					color: inherit;
				}
			}
		}
	}

	@media (max-width: 768px) {
		display: none;
	}
`;

export const CollectionNavigation = () => {
	const data = useStaticQuery(graphql`
		{
			prismicMenus {
				data {
					collections {
						collection {
							document {
								... on PrismicCollection {
									id
									url
									data {
										title {
											text
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const collectionsMenu = data.prismicMenus.data.collections.map(
		(content, index) => (
			<li key={`collection_navigation_item_${index}`}>
				<Link
					to={content.collection.document.url}
					aria-label={content.collection.document.data.title.text}
					partiallyActive
					activeClassName="current"
				>
					{content.collection.document.data.title.text}
				</Link>
			</li>
		)
	);

	if (collectionsMenu.length > 1) {
		return (
			<NavContainer>
				<ol>{collectionsMenu}</ol>
			</NavContainer>
		);
	} else {
		return null;
	}
};
