import React from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Components
import { FlexContainer } from "../containers/flex-container";
import { ContentContainer } from "../containers/container";

const Textbox = styled.div`
	max-width: 780px;

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}

	& p:empty {
		display: none;
	}

	@media (max-width: 1024px) {
	}
`;

export const PageTitle = ({ title, pageType }) => (
	<ContentContainer>
		<FlexContainer
			desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
			tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
			mobilePadding={`0 15px`}
			cv={false}
		>
			<TransitionContainer>
				<Row>
					<Col col={12} md={9} lg={6} xl={7}>
						<Textbox
							dangerouslySetInnerHTML={{
								__html: title,
							}}
						/>
					</Col>
				</Row>
			</TransitionContainer>
		</FlexContainer>
	</ContentContainer>
);
