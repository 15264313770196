import React, { useEffect, useContext } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// Context
import { FooterColor } from "../components/context/footer-color";

// Components
import { ContentContainer } from "../components/containers/container";
import { PageTitle } from "../components/text/page-title";
import { Spacer } from "../components/utils/spacer";

// Utils
import { DetermineModuleToDisplay } from "../components/utils/determine-module-to-display";
import { DetermineFooterColor } from "../components/utils/determine-footer-color";

const PageContent = ({ data }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		const calcFooterColor = DetermineFooterColor(
			data.prismicPage.data.body[data.prismicPage.data.body.length - 1]
				.slice_type
		);

		setFooterColor(calcFooterColor);
	}, []);

	const content = data.prismicPage.data.body.map((content, outerIndex) => {
		let previousContentSliceType =
			outerIndex !== 0
				? data.prismicPage.data.body[outerIndex - 1].slice_type
				: false;

		return (
			<ContentContainer
				key={`homepage_module_wrapper_${outerIndex}`}
				marginOverride={
					(content.slice_type === "enquiry_form" ||
						content.slice_type === "mailchimp" ||
						previousContentSliceType === "related_pages") &&
					(previousContentSliceType === "video_-_banner" ||
						previousContentSliceType === "image_-_banner" ||
						previousContentSliceType === "related_products")
				}
			>
				<DetermineModuleToDisplay
					content={content}
					pageType={`page`}
					contentIndex={outerIndex}
				/>
				{outerIndex === data.prismicPage.data.body.length - 1 &&
					content.slice_type !== "enquiry_form" &&
					content.slice_type !== "mailchimp" &&
					content.slice_type !== "video_-_banner" &&
					content.slice_type !== "image_-_banner" &&
					content.slice_type !== "related_products" && <Spacer />}
			</ContentContainer>
		);
	});

	return (
		<>
			<Helmet
				title={`${
					data.prismicPage.data.seo_title !== null
						? data.prismicPage.data.seo_title
						: data.prismicPage.data.title.text
				} | Christian Watson`}
				meta={[
					{
						name: "og:title",
						content:
							data.prismicPage.data.seo_title !== null
								? data.prismicPage.data.seo_title
								: `${data.prismicPage.data.title.text} | Christian Watson`,
					},
					{
						name: "description",
						content: `${data.prismicPage.data.seo_description !== null &&
							data.prismicPage.data.seo_description}`,
					},
					{
						name: "og:description",
						content: `${data.prismicPage.data.seo_description !== null &&
							data.prismicPage.data.seo_description}`,
					},
					{
						name: "twitter:title",
						content:
							data.prismicPage.data.seo_title !== null
								? data.prismicPage.data.seo_title
								: `${data.prismicPage.data.title.text} | Christian Watson`,
					},
					{
						name: "twitter:description",
						content: `${data.prismicPage.data.seo_description !== null &&
							data.prismicPage.data.seo_description}`,
					},
				]}
			/>

			<PageTitle title={data.prismicPage.data.title.html} />

			{content}
		</>
	);
};

const Page = ({ data }) => <PageContent data={data} />;

export default withPreview(Page);

export const query = graphql`
	query Page($uid: String!) {
		prismicPage(uid: { eq: $uid }) {
			prismicId
			data {
				title {
					text
					html
				}
				seo_title
				seo_description
				body {
					... on PrismicPageBodyImage {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_position
							image_size
							link {
								url
								type
							}
						}
					}
					... on PrismicPageBodyVideo {
						id
						slice_type
						primary {
							video {
								width
								height
								html
								embed_url
								title
							}
							video_position
							video_size
						}
					}
					... on PrismicPageBodyImageBanner {
						id
						slice_type
						primary {
							image {
								fluid(
									imgixParams: { ar: "16:3", fit: "clamp", crop: "entropy" }
								) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							link {
								url
								type
							}
						}
					}
					... on PrismicPageBodyVideoBanner {
						id
						slice_type
						primary {
							link {
								url
								type
							}
							video {
								width
								height
								html
								embed_url
								title
							}
						}
					}
					... on PrismicPageBodyText {
						id
						slice_type
						primary {
							text {
								html
							}
							text_width
							text_columns
							text_position
						}
					}
					... on PrismicPageBodyCollections {
						id
						slice_type
						items {
							collection {
								document {
									... on PrismicCollection {
										id
										url
										prismicId
										data {
											thumbnail {
												dimensions {
													width
													height
												}
												fluid {
													srcSetWebp
													srcWebp
													aspectRatio
												}
												alt
											}
											title {
												text
											}
										}
									}
								}
							}
						}
						primary {
							collections_title {
								html
							}
						}
					}
					... on PrismicPageBodyMailchimp {
						id
						slice_type
						primary {
							mailchimp_form {
								document {
									... on PrismicMailchimp {
										id
										prismicId
										data {
											call_to_action_text
											privacy_policy {
												html
											}
											text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicPageBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}
							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicPageBodyEnquiryForm {
						id
						slice_type
						primary {
							enquiry_form {
								document {
									... on PrismicEnquiryForm {
										id
										data {
											form_title {
												html
											}
											form_text {
												html
											}
											form_disclaimer_text {
												html
											}
											form_message_placeholder
											dropdown_options {
												dropdown_option
												linked_email_address
											}
											additional_form_fields {
												additional_form_field
											}
											enable_file_upload
											file_upload_text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicPageBodyImageWithText {
						id
						slice_type
						primary {
							image_with_text {
								html
							}
							image_with_text_image {
								dimensions {
									width
									height
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							image_with_text_title {
								html
							}
							text_position
							text_vertical_position
						}
					}
					... on PrismicPageBodyFaq {
						id
						slice_type
						primary {
							faq_section_title {
								html
							}
						}
						items {
							faq_title {
								text
							}
							faq_text {
								html
							}
						}
					}
					... on PrismicPageBodySlideshow {
						id
						slice_type
						primary {
							slideshow_title {
								html
							}
						}
						items {
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							text {
								html
							}
						}
					}
					... on PrismicPageBodySlideshowWithText {
						id
						slice_type
						primary {
							slideshow_with_text_title {
								html
							}
							slideshow_with_text {
								html
							}
						}
						items {
							image_title {
								html
								text
							}
							image_text {
								html
								text
							}
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
						}
					}
					... on PrismicPageBodySocialLinks {
						id
						slice_type
						items {
							social_link {
								html
							}
						}
					}
					... on PrismicPageBodyRelatedPages {
						id
						slice_type
						items {
							page {
								url
								document {
									... on PrismicPage {
										id
										url
										data {
											title {
												text
											}
											thumbnail {
												alt
												fluid {
													srcWebp
													srcSetWebp
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
