import React, { useEffect, useContext } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { DetermineModuleToDisplay } from "../components/utils/determine-module-to-display";
import { ContentContainer } from "../components/containers/container";
import { Spacer } from "../components/utils/spacer";

// Colors
import { tertiary } from "../components/utils/colors";

// Context
import { FooterColor } from "../components/context/footer-color";

const Page = styled.div`
	width: 100%;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 30px;

	@media (max-width: 767px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		grid-gap: 0;
	}
`;

const SignOffText = styled.div`
	max-width: 570px;
	grid-column: 4 / 11;

	& p:first-of-type {
		margin-top: 0;
	}

	@media (max-width: 840px) {
		grid-column: 4 / 13;
		order: 3;
	}

	@media (max-width: 767px) {
		margin: 45px auto 0 auto;

		grid-column: unset;
	}
`;

const ArticleLinks = styled.div`
	text-align: ${(props) => props.textAlign};

	&:first-of-type {
		grid-column: 1 / 3;
	}

	&:last-of-type {
		grid-column: 11 / 13;
	}

	@media (max-width: 840px) {
		&:first-of-type {
			order: 1;
			grid-column: 1 / 7;
		}

		&:last-of-type {
			order: 2;
			grid-column: 7 / 13;
		}
	}

	@media (max-width: 767px) {
		&:first-of-type,
		&:last-of-type {
			max-width: 50%;
			width: 100%;

			text-align: center;

			grid-column: unset;
		}
	}
`;

const Disclaimer = styled.div`
	max-width: 570px;
	grid-column: 4 / 11;

	@media (max-width: 840px) {
		grid-column: 4 / 13;
	}

	@media (max-width: 767px) {
		margin: 0 auto;

		grid-column: unset;
	}
`;

const ArticleContent = ({ data, pageContext }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const content = data.prismicArticle.data.body.map((content, outerIndex) => {
		return (
			<ContentContainer key={`collection_module_wrapper_${outerIndex}`}>
				<DetermineModuleToDisplay
					content={content}
					pageType={`article`}
					article={data.prismicArticle}
					contentIndex={outerIndex}
				/>
			</ContentContainer>
		);
	});

	return (
		<>
			<Helmet
				title={`${data.prismicArticle.data.title.text} | Christian Watson`}
				meta={[
					{
						name: "description",
						content: `${data.prismicArticle.data.excerpt.text}`,
					},
					{
						name: "og:title",
						content: `${data.prismicArticle.data.title.text} | Christian Watson`,
					},
					{
						name: "og:image",
						content: `${
							data.prismicArticle.data.thumbnail.fluid !== null
								? data.prismicArticle.data.thumbnail.fluid.src
								: ``
						}`,
					},
					{
						name: "og:image:secure_url",
						content: `${
							data.prismicArticle.data.thumbnail.fluid !== null
								? data.prismicArticle.data.thumbnail.fluid.src
								: ``
						}`,
					},
					{
						name: "og:description",
						content: `${data.prismicArticle.data.excerpt.text}`,
					},
					{
						name: "og:image:width",
						content: `1200`,
					},
					{
						name: "og:image:height",
						content: `630`,
					},
					{
						name: "og:locale",
						content: `en`,
					},
					{
						name: "twitter:title",
						content: `Christian Watson`,
					},
					{
						name: "twitter:description",
						content: `${data.prismicArticle.data.excerpt.text}`,
					},
					{
						name: "twitter:card",
						content: `summary_large_image`,
					},
					{
						name: "twitter:image",
						content: `${
							data.prismicArticle.data.thumbnail.fluid !== null
								? data.prismicArticle.data.thumbnail.fluid.src
								: ``
						}`,
					},
				]}
			/>

			<Page>
				{content}

				<ContentContainer key={`disclaimer`}>
					<FlexContainer cv={true}>
						<Grid>
							<ArticleLinks textAlign={`left`}>
								<Link to={`/journal/${pageContext.prev.uid}`}>
									Previous post
								</Link>
							</ArticleLinks>

							<SignOffText
								className=""
								dangerouslySetInnerHTML={{
									__html: data.prismicBlog.data.signoff_text.html,
								}}
							/>

							<ArticleLinks textAlign={`right`}>
								<Link to={`/journal/${pageContext.next.uid}`}>Next post</Link>
							</ArticleLinks>
						</Grid>
						<Grid>
							<Disclaimer
								className="disclaimer small-text"
								dangerouslySetInnerHTML={{
									__html: data.prismicBlog.data.disclaimer.html,
								}}
							/>
						</Grid>
					</FlexContainer>
				</ContentContainer>
			</Page>
			<Spacer />
		</>
	);
};

const Article = ({ data, pageContext }) => (
	<ArticleContent data={data} pageContext={pageContext} />
);

export default withPreview(Article);

export const query = graphql`
	query Article($uid: String!) {
		prismicBlog {
			data {
				signoff_text {
					html
				}
				disclaimer {
					html
				}
			}
		}
		prismicArticle(uid: { eq: $uid }) {
			prismicId
			first_publication_date(formatString: "DD/MM/YYYY")
			url
			data {
				title {
					html
					text
				}
				author {
					text
				}
				excerpt {
					text
				}
				thumbnail {
					alt
					dimensions {
						height
						width
					}
					fluid(imgixParams: { w: 1200, h: 630 }) {
						src
					}
				}
				body {
					... on PrismicArticleBodyImage {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_credit {
								html
								text
							}
							image_position
							image_size
							link {
								url
								type
							}
						}
					}
					... on PrismicArticleBodyVideo {
						id
						slice_type
						primary {
							video {
								width
								height
								html
								embed_url
							}
							link {
								url
								type
							}
							video_position
							video_size
						}
					}
					... on PrismicArticleBodyImageBanner {
						id
						slice_type
						primary {
							image {
								fluid(
									imgixParams: { ar: "16:3", fit: "clamp", crop: "entropy" }
								) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_credit {
								html
								text
							}
							link {
								url
								type
							}
						}
					}
					... on PrismicArticleBodyVideoBanner {
						id
						slice_type
						primary {
							link {
								url
								type
							}
							video {
								width
								height
								html
								embed_url
								title
							}
						}
					}
					... on PrismicArticleBodyText {
						id
						slice_type
						primary {
							text {
								html
							}
							text_position
						}
					}
					... on PrismicArticleBodyOpeningText {
						id
						slice_type
						primary {
							text {
								html
								text
							}
						}
					}
					... on PrismicArticleBodyCollections {
						id
						slice_type
						items {
							collection {
								document {
									... on PrismicArticle {
										id
										url
										prismicId
										data {
											thumbnail {
												fluid {
													srcSetWebp
													srcWebp
													aspectRatio
												}
												alt
											}
											title {
												text
											}
										}
									}
								}
							}
						}
						primary {
							collections_title {
								html
							}
						}
					}
					... on PrismicArticleBodyMailchimp {
						id
						slice_type
						primary {
							mailchimp_form {
								document {
									... on PrismicMailchimp {
										id
										prismicId
										data {
											call_to_action_text
											privacy_policy {
												html
											}
											text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicArticleBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}
							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicArticleBodyEnquiryForm {
						id
						slice_type
						primary {
							enquiry_form {
								document {
									... on PrismicEnquiryForm {
										id
										data {
											form_title {
												html
											}
											form_text {
												html
											}
											form_message_placeholder
											form_disclaimer_text {
												html
											}
											dropdown_options {
												dropdown_option
												linked_email_address
											}
											additional_form_fields {
												additional_form_field
											}
											enable_file_upload
											file_upload_text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicArticleBodyImageWithText {
						id
						slice_type
						primary {
							image_with_text {
								html
							}
							image_with_text_image {
								dimensions {
									width
									height
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							image_credit {
								html
								text
							}
							image_with_text_title {
								html
							}
							text_position
							text_vertical_position
						}
					}
					... on PrismicArticleBodyFaq {
						id
						slice_type
						primary {
							faq_section_title {
								html
							}
						}
						items {
							faq_title {
								text
							}
							faq_text {
								html
							}
						}
					}
					... on PrismicArticleBodySlideshow {
						id
						slice_type
						primary {
							slideshow_title {
								html
							}
						}
						items {
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							text {
								html
							}
						}
					}
					... on PrismicArticleBodySlideshowWithText {
						id
						slice_type
						primary {
							slideshow_with_text_title {
								html
							}
							slideshow_with_text {
								html
							}
						}
						items {
							image_title {
								html
								text
							}
							image_text {
								html
								text
							}
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
						}
					}
				}
			}
		}
	}
`;
