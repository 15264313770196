import React, { useEffect, useContext } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { Spacer } from "../components/utils/spacer";

// Context
import { FooterColor } from "../components/context/footer-color";
import { tertiary } from "../components/utils/colors";

const Page = styled.div`
	margin: 150px 0 0 0;

	& .text-container {
		max-width: 600px;
		margin: 0 auto;
	}
`;

const PolicyPageContent = ({ data }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	return (
		<>
			<Helmet
				title={`${data.prismicPolicyPage.data.title.text} | Christian Watson`}
			/>

			<FlexContainer cv={true}>
				<Row justifyContent={`center`}>
					<Col col={12}>
						<div
							className="text-container"
							dangerouslySetInnerHTML={{
								__html: data.prismicPolicyPage.data.title.html,
							}}
						/>
					</Col>
				</Row>

				<Row justifyContent={`center`}>
					<Col col={12}>
						<div
							className="text-container"
							dangerouslySetInnerHTML={{
								__html: data.prismicPolicyPage.data.text.html,
							}}
						/>
					</Col>
				</Row>
			</FlexContainer>
		</>
	);
};

const PolicyPage = ({ data }) => (
	<>
		<Page>
			<PolicyPageContent data={data} />
		</Page>
		<Spacer />
	</>
);

export default withPreview(PolicyPage);

export const query = graphql`
	query PolicyPage($uid: String!) {
		prismicPolicyPage(uid: { eq: $uid }) {
			prismicId
			data {
				title {
					html
					text
				}
				text {
					html
				}
			}
		}
	}
`;
